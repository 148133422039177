const data = {
  "andrewstech": {
    "handle": "andrewstech",
    "avatar": "https://github.com/andrewstech.png"
  }
}

export default data

// == Things that can be overwritten ==

// "name": "andrewstech",
// "handle": "andrewstech",
// "email": null,
// "company": null,
// "hireable": null,
// "location": "United Kingdom",
// "twitter_username": "andrewstech1"
// "avatar": "https://avatars.githubusercontent.com/u/45342431?s=60&v=4",
// "profile": "https://github.com/andrewstech",
// "blog": "https://andrewstech.me",
// "bio": "A Student and a Coder\r\n\r\nhttps://youtube.com/andrewstech",